import { ValueOf } from '@powerednow/type-definitions';
import * as Bluebird from 'bluebird';
import SUBSCRIPTION, {
    FEATURES, MODE, CALCULATED_FEATURES, WHITE_LABEL_IDS,
} from '@powerednow/shared/constants/subscription';
import { TYPE_PERMISSION_MAP } from '@powerednow/shared/constants/document';
import _ from 'lodash';
import { memoizeRunningPromise } from 'decorators';
import * as dateUtils from '@powerednow/shared/modules/utilities/date';
import { ifInstanceOf } from 'modules/typedCatch';
import type { CompanyType } from './index';
import type { ConsumptionExtraType } from '../consumptionExtra';
import type { PurchasesType } from '../purchases';
import PurchaseSetup, { NoAssociatedTierError } from '../purchaseSetup';
import moment from '../../momentOverride';
import type { ProductTierToFeatureType } from '../productTierToFeature';
import type { UserType } from '../user';
import PurchaseSetupEntity from '../purchaseSetup/entity';
import { ModelFields } from '../entity';
import type FeatureEntity from '../feature/entity';
import { MESSAGES } from '../../../constants';
import MessageRecipient from '../messageRecipient';
import Customer from '../customer';
import PARTNERS from '../../../constants/partner';

const orderBy = require('orderby');

export type UserCreationInfo = {
    default: {
        max: number
        used: number
    },
    extra: {
        max: number
        used: number
    },
    freeSlots: number
    maxSlots: number
    usedSlots: number
    teamEnabled: boolean
}

export interface SubscriptionInfoType {
    userCreationInfo: UserCreationInfo
    tierName: string
    mode: ValueOf<typeof SUBSCRIPTION.MODE>
    expiration_date: Date
    expiration_date_string: string
    remaining_time: number
    remaining_days: number
    time: number
    days: number
    description: string
    teamEnabled: boolean
    maxUsers: number
    teamVersion: boolean
    activeUsers: number
    paymentCycle: number
    everSubscribed: boolean
    product_id: string
    purchase_origin: ValueOf<typeof SUBSCRIPTION.PURCHASE_ORIGIN_ID>,
    purchaseSetup: ModelFields<PurchaseSetupEntity>,
    canInviteConstructor: boolean
    canUseTracking: boolean
    deviceSwitch: number
    concurrentLoginLimit: number
    emailLimit: number | boolean
    smsLimit: number | boolean
}

export default class SubscriptionInfo implements SubscriptionInfoType {
    company: CompanyType = null;

    userCreationInfo: UserCreationInfo;

    tierName: string;

    mode: ValueOf<typeof SUBSCRIPTION.MODE>;

    expiration_date: Date;

    expiration_date_string: string;

    remaining_time: number;

    remaining_days: number;

    time: number;

    days: number;

    description: string;

    teamEnabled: boolean;

    maxUsers: number;

    teamVersion: boolean;

    activeUsers: number;

    paymentCycle: number;

    everSubscribed: boolean;

    product_id: string;

    purchase_origin: ValueOf<typeof SUBSCRIPTION.PURCHASE_ORIGIN_ID>;

    purchaseSetup: ModelFields<PurchaseSetupEntity>;

    feature: ModelFields<FeatureEntity>;

    canInviteConstructor: boolean;

    canUseTracking: boolean;

    deviceSwitch: number;

    concurrentLoginLimit: number;

    emailLimit: number | boolean;

    smsLimit: number | boolean;

    private trialId: number;

    private expiredId: number;

    private whitelabelId: number;

    private featureValues: { [key: string]: boolean | number } = {};

    private messageLimitMap = {
        [MESSAGES.TYPES.SMS]: FEATURES.SMS_LIMIT,
        [MESSAGES.TYPES.WORK]: FEATURES.SMS_LIMIT,
        [MESSAGES.TYPES.HOME]: FEATURES.SMS_LIMIT,
        [MESSAGES.TYPES.EMAIL]: FEATURES.EMAIL_LIMIT,
    };

    constructor(company: CompanyType) {
        this.company = company;
    }

    permissionMap = {
        async [CALCULATED_FEATURES.CAN_SEND_DOC]() {
            const docCreationInfo = await this.getDocumentCreationInfo();
            return docCreationInfo.sendAllowed;
        },
        async [CALCULATED_FEATURES.CAN_CREATE_DOC]({ documentTypeId = null }) {
            const docCreationInfo = await this.getDocumentCreationInfo();
            const docTypeFeatureId = TYPE_PERMISSION_MAP[documentTypeId];
            const feature = _.findKey(FEATURES, featureId => featureId === docTypeFeatureId);

            return docCreationInfo.creationAllowed && (!docCreationInfo.documents || docCreationInfo.documents[feature]);
        },
        async [CALCULATED_FEATURES.CAN_CREATE_CUSTOMER]() {
            const canUseContacts = this.isSubscriptionAllows(FEATURES.CAN_USE_CONTACTS);
            const limit = this.getCachedFeatureValue(FEATURES.CUSTOMER_LIMIT);
            if (limit > -1) {
                const allCustomers = await Customer.getAll(this.company);
                return limit > allCustomers.length && canUseContacts;
            }
            return canUseContacts;
        },
        [CALCULATED_FEATURES.CAN_USE_POSTCODE]() {
            const limit = this.getCachedFeatureValue(FEATURES.POSTCODE_LIMIT);
            return limit === -1 || Boolean(limit);
        },
        [CALCULATED_FEATURES.CAN_USE_SMS]() {
            const limit = this.getCachedFeatureValue(FEATURES.SMS_LIMIT);
            return limit === -1 || Boolean(limit);
        },
        async [CALCULATED_FEATURES.CAN_SEND_LIMITED_MESSAGE]({ messageType = MESSAGES.TYPES.SMS }) {
            return this.isUnderMessageLimit(messageType);
        },
    };

    public setTrialId(id: number) {
        this.trialId = id;
    }

    public setExpiredId(id: number) {
        this.expiredId = id;
    }

    public setWhiteLabelId(id: number) {
        this.whitelabelId = id;
    }

    isSubscriptionAllows(action: string | number, options = {}) {
        const actionId = typeof action === 'number' ? action : FEATURES[action];
        if (actionId) {
            const featureValue = this.getCachedFeatureValue(actionId);
            if (featureValue !== undefined) {
                return featureValue;
            }
        }
        if (!CALCULATED_FEATURES[action] || !this.permissionMap[CALCULATED_FEATURES[action]]) {
            throw new Error(`Unknown feature ${action}`);
        }
        return this.permissionMap[action].call(this, options);
    }

    async isUnderMessageLimit(messageType) {
        const savedLimit = await this.company.getSettingValue(this.messageLimitMap[messageType]);
        const limit = parseInt(savedLimit.toString(), 10) || this.getMessageTypeLimit(messageType);
        const oneMonthBefore = new Date();
        oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);

        if (limit !== -1) {
            const allMessages = await MessageRecipient.getAll(this.company);
            const messageCount = allMessages.filter(message => [MESSAGES.TYPES.EMAIL, MESSAGES.TYPES.SMS, MESSAGES.TYPES.WORK, MESSAGES.TYPES.HOME].includes(message.data.contactmethodtype_id)
                    && oneMonthBefore.getTime() < new Date(message.data.dt_created).getTime()).length;
            return messageCount < limit;
        }
        return true;
    }

    getMessageTypeLimit(messageType) {
        const limitKey = this.messageLimitMap[messageType];
        if (limitKey) {
            const limit = this.getCachedFeatureValue(limitKey);
            if (limit !== -1) {
                return limit;
            }
        }
        return -1;
    }

    isSubscriptionExpired() {
        return (this.mode === SUBSCRIPTION.MODE.EXPIRED);
    }

    /**
     * This function should prioritize active subscriptions. If there is no active subscriptions then it should return
     * subscriptions with dunning period or nothing.
     */
    async getActiveSubscriptions() {
        try {
            const purchases = await this.getWeightedPurchases();
            const activeWithDunningPeriod = purchases.filter(purchase => purchase.isActiveWithDunningPeriod());
            const activePeriod = activeWithDunningPeriod.filter(purchase => purchase.isActive());
            return !activePeriod.length ? activeWithDunningPeriod : activePeriod;
        } catch (e) {
            return [];
        }
    }

    async getWeightedPurchases(): Promise<PurchasesType[]> {
        return Bluebird.map(await this.company.getAllPurchases(), async (purchase: PurchasesType) => {
            (purchase as any)._purchaseSetup = await purchase.findPurchaseSetup();
            return purchase;
        })
            .then(purchases => SubscriptionInfo.sortPurchases(purchases));
    }

    private static sortPurchases(purchases: PurchasesType[]): PurchasesType[] {
        orderBy(purchases, [{
            predicate: 'data.purchase_date',
            reverse: true,
        }, {
            predicate: '_purchaseSetup.data.weight',
            reverse: true,
        }]);
        return purchases;
    }

    async getCurrentSubscription(): Promise<PurchasesType> {
        return (await this.getActiveSubscriptions())
            .slice(0, 1)
            .shift();
    }

    @memoizeRunningPromise
    async getCurrentPurchaseSetup() {
        const purchase = await this.getCurrentSubscription();
        if (purchase) {
            return purchase.getPurchaseSetup();
        }
        const trialId = await this.getTrialId();
        const expiredId = await this.getExpiredId();
        const nowTime = (new Date()).getTime();
        const trialExpires = await this.getTrialExpirationDate();
        const isTrialPeriod = !trialExpires || nowTime < trialExpires.getTime();
        const allPurchaseSetup = await PurchaseSetup.getAll(this.company);
        return allPurchaseSetup.find(purchaseSetup => purchaseSetup.data.id === (isTrialPeriod ? trialId : expiredId));
    }

    async getTrialExpirationDate() {
        const feature = await this.company.getFeature();
        if (!feature) {
            console.warn('This function can only be called once the feature config is set');
            return null;
        }
        const { dt_trial_expires } = feature.data;
        if (dt_trial_expires) {
            return dateUtils.parse(dt_trial_expires);
        }
        return null;
    }

    async getCurrentTier() {
        const purchaseSetup = await this.getCurrentPurchaseSetup();
        if (purchaseSetup) {
            const whitelabelId = await this.getWhiteLabelId();
            return purchaseSetup.getTier(whitelabelId)
                .catch(ifInstanceOf(NoAssociatedTierError, async error => {
                    const companyWhitelabelId = await this.getCompanyWhiteLabelId();
                    if (companyWhitelabelId !== whitelabelId) {
                        return purchaseSetup.getTier(companyWhitelabelId);
                    }
                    throw error;
                }));
        }
        return null;
    }

    async getActiveUserConsumables(): Promise<ConsumptionExtraType[]> {
        //
        // Consumable users are only available if there is an active subscription and the subscription supports multiuser mode
        //
        const currentPurchase = await this.getCurrentSubscription();
        const currentPurchaseSetup = await currentPurchase?.getPurchaseSetup();
        if (!currentPurchase || !currentPurchaseSetup || !currentPurchaseSetup.isSupportMultiUserMode()) {
            return [];
        }
        const extraUsers = await this.company.getAllExtraUser();
        return extraUsers.filter(extraUser => !extraUser.isExpired());
    }

    async countActiveUserSlots() {
        const records = await this.getActiveUserConsumables();
        let users = 0;

        await Bluebird.each(records, async consumptionRecord => {
            const purchaseSetup = await consumptionRecord.getPurchaseSetup();
            const defaultUserNum = isNaN(purchaseSetup.data.users) ? 1 : purchaseSetup.data.users;
            const consumptionQuantity = isNaN(consumptionRecord.data.quantity) ? 1 : consumptionRecord.data.quantity;
            users += defaultUserNum * consumptionQuantity;
        });

        return users;
    }

    async countUnusedActiveSlots() {
        const records = (await this.getActiveUserConsumables()).filter(record => record.data.count > 0);
        return records.reduce((acc, record) => acc + (isNaN(record.data.count) ? 1 : record.data.count), 0);
    }

    async isTeamTrialEnabled() {
        const now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        const feature = await this.company.getFeature();
        return feature.data.dt_team_trial_expires > now;
    }

    async getUsedUserRecords() {
        return (await this.company.getAllUser())
            .filter(record => record.data.is_active && (record.data.consumption_id === -1 || !record.data.consumption_id));
    }

    async getCurrentFeatures(): Promise<ProductTierToFeatureType[]> {
        const currentTier = await this.getCurrentTier();
        if (currentTier) {
            return currentTier.getAllProductTierToFeature();
        }
        return [];
    }

    async getFeatureValue(featureCode: number): Promise<boolean | number> {
        const enabledFeatures = await this.getCurrentFeatures();
        const featureInstance = enabledFeatures.find(feature => feature.data.product_feature_id === featureCode);
        if (!featureInstance) {
            return false;
        }
        if (typeof featureInstance.data.value === 'number') {
            return featureInstance.data.value;
        }
        return true;
    }

    private async cacheFeatureValues() {
        const purchaseSetup = await this.getCurrentPurchaseSetup();
        ((purchaseSetup && Object.entries(purchaseSetup.data.getPureDataValues())) || []).forEach(([key, value]) => {
            if (typeof value !== 'string') this.featureValues[key] = value;
        });
        return Bluebird.map(Object.values(FEATURES), async feature => {
            this.featureValues[feature] = await this.getFeatureValue(feature);
        });
    }

    public getCachedFeatureValue(featureId): boolean | number {
        if (this.featureValues[featureId] === undefined) {
            throw new Error(`Feature ${featureId} is not cached`);
        }
        return this.featureValues[featureId];
    }

    private async getActiveUsers(): Promise<UserType[]> {
        return (await this.company.getAllUser()).filter(user => user.data.is_active);
    }

    // @TODO as discussed this is probably not the ideal solution since
    // this whitelabel_id will not change in the company table if the user
    // changes from whitelabel app to powerednow hence the user might not see
    // powerednow related features/subscriptions etc.
    private async getWhiteLabelId() {
        if (this.whitelabelId) {
            return this.whitelabelId;
        }
        return this.getCompanyWhiteLabelId();
    }

    private async getCompanyWhiteLabelId() {
        const whiteLabel = await this.company.getWhiteLabel();
        return Number(whiteLabel?.data?.fallback_whitelabel_id || whiteLabel?.data?.id) || null;
    }

    private async getTrialId() {
        if (this.trialId) {
            return this.trialId;
        }
        const whitelabelId = await this.getWhiteLabelId();
        if (whitelabelId !== WHITE_LABEL_IDS.ONE_TRADE_APP) {
            return SUBSCRIPTION.PURCHASE_SETUP_ID.TRIAL;
        }

        return SUBSCRIPTION.PURCHASE_SETUP_ID['1TA_TRIAL'];
    }

    private async getExpiredId() {
        if (this.expiredId) {
            return this.expiredId;
        }
        const whitelabelId = await this.getWhiteLabelId();
        if (whitelabelId !== WHITE_LABEL_IDS.ONE_TRADE_APP) {
            return SUBSCRIPTION.PURCHASE_SETUP_ID.EXPIRED;
        }

        return SUBSCRIPTION.PURCHASE_SETUP_ID.EXPIRED_NO_DOC;
    }

    public async init() {
        await this.cacheFeatureValues();
        const feature = await this.company.getFeature();
        this.feature = feature.data.getPureDataValues();
        await this.updateSubscriptionInfo();
    }

    async updateSubscriptionInfo() {
        let mode: ValueOf<typeof MODE> = MODE.EXPIRED;
        let expiration_date = null;
        let remaining_time = 0;
        let remaining_days = 0;
        let product_id = '';
        let purchaseOrigin = '';

        // unsigned (not negative)
        let time = 0;
        let days = 0;

        const now = new Date();
        const nowTime = now.getTime();
        const purchaseSetup = await this.getCurrentPurchaseSetup();

        const latestActiveSubscription = await this.getCurrentSubscription();

        const trial_expires = await this.getTrialExpirationDate();
        let expiration_date_string = '';

        if (latestActiveSubscription) {
            //
            // Subscribed
            //
            mode = MODE.SUBSCRIBED;
            product_id = latestActiveSubscription.data.product;
            expiration_date = dateUtils.parse(latestActiveSubscription.data.expires_date);
            purchaseOrigin = latestActiveSubscription.data.purchase_origin;
        } else if (!trial_expires || nowTime < trial_expires.getTime()) {
            //
            // Trial
            //
            mode = MODE.TRIAL;
            expiration_date = trial_expires;
        } else {
            //
            // Expired
            //
            const whitelabelId = this.company.data.whitelabel_id;
            const whiteLabel = await this.company.getWhiteLabel();
            const shouldUseTrialMode = whiteLabel?.data?.fallback_whitelabel_id && ![
                PARTNERS.ID.GRAHAM,
                PARTNERS.ID.ONETRADE,
                PARTNERS.ID.POWEREDNOW,
            ].includes(whitelabelId);
            mode = shouldUseTrialMode ? MODE.TRIAL : MODE.EXPIRED;
            expiration_date = await this.getLatestExpiryDate();
        }

        if (trial_expires && dateUtils.isValid(expiration_date)) {
            remaining_time = expiration_date.getTime() - nowTime;
            const float_days = remaining_time / 1000 / 60 / 60 / 24;

            if (remaining_time > 0) {
                remaining_days = Math.round(float_days);
            } else {
                remaining_days = Math.ceil(float_days);
            }

            if (mode === MODE.EXPIRED) {
                time = Math.abs(remaining_time);
                days = Math.abs(remaining_days);
            } else {
                time = remaining_time;
                days = remaining_days;
            }

            if (expiration_date) {
                expiration_date_string = dateUtils.format(expiration_date);
            }
        } else {
            //
            // before trial period started
            //
            remaining_days = -1;
            remaining_time = -1;
            time = -1;
            days = -1;
            expiration_date_string = '';
        }
        //
        // Get the active user count
        //
        const activeUsers = await this.getActiveUsers();

        this.userCreationInfo = await this.getUserCreationInfo();
        this.tierName = purchaseSetup.data.product_name;
        this.mode = mode;
        this.expiration_date = expiration_date;
        this.expiration_date_string = expiration_date_string;
        this.remaining_time = remaining_time;
        this.remaining_days = remaining_days;
        this.time = time;
        this.days = days;
        this.teamEnabled = this.userCreationInfo.teamEnabled;
        this.maxUsers = this.userCreationInfo.maxSlots;
        this.teamVersion = purchaseSetup.isSupportMultiUserMode();
        this.activeUsers = activeUsers.length;
        this.paymentCycle = latestActiveSubscription ? await latestActiveSubscription.getRenewCycle() : 0;
        this.everSubscribed = await this.company.hasEverSubscribed();
        this.product_id = product_id;
        this.purchase_origin = purchaseOrigin;
        this.purchaseSetup = purchaseSetup.data.getPureDataValues();
        this.canInviteConstructor = purchaseSetup.data.inviteConstructor !== 0;
        this.canUseTracking = purchaseSetup.data.tracking;
        this.deviceSwitch = purchaseSetup.data.deviceSwitch > -1 ? purchaseSetup.data.deviceSwitch : null;
        this.concurrentLoginLimit = await this.getUserConcurrentLoginLimit();
        const currentSetting = await this.company.getCompanySettingOrNull(FEATURES.EMAIL_LIMIT) || this.getCachedFeatureValue(FEATURES.EMAIL_LIMIT);
        this.emailLimit = parseInt(currentSetting.toString(), 10);
        this.smsLimit = this.getCachedFeatureValue(FEATURES.SMS_LIMIT);
    }

    private async getUserCreationInfo(): Promise<UserCreationInfo> {
        const result: UserCreationInfo = {
            default: {
                max: 1,
                used: 0,
            },
            extra: {
                max: 0,
                used: 0,
            },
            freeSlots: 0,
            maxSlots: 0,
            usedSlots: 0,
            teamEnabled: false,
        };
        const activePurchase = await this.getCurrentSubscription();
        //
        // subscribed, get number based on product
        //
        if (activePurchase) {
            const purchaseSetup = await activePurchase?.getPurchaseSetup();
            result.default.max = Number((await this.getCachedFeatureValue(FEATURES.MAX_USERS)) || purchaseSetup.data.users);
            //
            // get extra slot statistics for multi user
            //
            if (purchaseSetup.isSupportMultiUserMode()) {
                result.extra.max = await this.countActiveUserSlots();
                result.extra.used = result.extra.max - await this.countUnusedActiveSlots();
                result.teamEnabled = true;
            }
        } else {
            //
            // not subscribed
            //
            result.default.max = Number((await this.getCachedFeatureValue(FEATURES.MAX_USERS)) || 1);
            //
            // get team trial settings
            //  - app should also check "purchaseSetupRecordInfo.team_trial_enabled === 1"
            //  BUT not for now: should enable for all ps records to allow post-teamTrial override works
            //
            if (await this.isTeamTrialEnabled()) {
                result.teamEnabled = true;
                result.default.max = 6;
            }
        }
        //
        // get default used
        //
        const usedDefaultRecords = await this.getUsedUserRecords();
        result.default.used = usedDefaultRecords.length;
        //
        // calc free slots
        //
        result.maxSlots = result.extra.max + result.default.max;
        result.usedSlots = result.extra.used + result.default.used;
        result.freeSlots = result.maxSlots - result.usedSlots;

        return result;
    }

    public async getLatestExpiryDate() {
        const purchases = await this.company.getAllPurchases();
        if (purchases.length > 0) {
            const lastSubscriptionExpiry = Math.max(...purchases.map(purchase => dateUtils.parse(purchase.data.expires_date).getTime()));
            return dateUtils.parse(new Date(lastSubscriptionExpiry));
        }
        return this.getTrialExpirationDate();
    }

    async getUserConcurrentLoginLimit() {
        let userConcurrentLoginLimit = this.getCachedFeatureValue(FEATURES.USER_CONCURRENT_LOGIN_LIMIT);
        if (this.feature.useConcurrentLoginLimit) {
            if (this.feature.concurrentLoginLimit) {
                userConcurrentLoginLimit = this.feature.concurrentLoginLimit;
            }
            // We convert the booleans to -1
            if (userConcurrentLoginLimit === true || userConcurrentLoginLimit === false) {
                userConcurrentLoginLimit = 1;
            }
            return userConcurrentLoginLimit > -1 ? userConcurrentLoginLimit : -1;
        }

        return userConcurrentLoginLimit === true || userConcurrentLoginLimit === false ? -1 : userConcurrentLoginLimit;
    }

    async getActiveExtraDocuments() {
        const extraDocuments = await this.company.getAllExtraDocument();
        return extraDocuments.filter(extraDocument => !extraDocument.isExpired() && extraDocument.data.count > 0);
    }

    async getDocumentAvailableCreationNumber() {
        const extraDocuments = await this.getActiveExtraDocuments();
        return extraDocuments.reduce((acc, record) => acc + (isNaN(record.data.count) ? 1 : record.data.count), 0);
    }

    async countUsedDocsExtra() {
        const records = await this.getActiveExtraDocuments();
        let documents = 0;

        await Bluebird.each(records, async consumptionRecord => {
            const purchaseSetup = await consumptionRecord.getPurchaseSetup();
            const defaultDocumentNum = isNaN(purchaseSetup.data.documentCreation) ? 1 : purchaseSetup.data.documentCreation;
            const consumptionQuantity = isNaN(consumptionRecord.data.count) ? 1 : consumptionRecord.data.count;
            documents += defaultDocumentNum - consumptionQuantity;
        });
        return documents;
    }

    async getDocumentRenewalPeriod() {
        const now = new Date();
        const latestActiveSubscription = await this.getCurrentSubscription();
        const latestActivePurchaseSetup = await this.getCurrentPurchaseSetup();

        if (latestActiveSubscription && latestActivePurchaseSetup) {
            const subPeriod = Number(latestActivePurchaseSetup.data.days_before_expires);
            const purchaseDate = dateUtils.parse(latestActiveSubscription.data.purchase_date);
            const expireDate = dateUtils.parse(latestActiveSubscription.data.expires_date);
            if (subPeriod !== 31) {
                purchaseDate.setFullYear(now.getFullYear());
                expireDate.setFullYear(now.getFullYear());

                if (expireDate.getDate() <= now.getDate()) {
                    purchaseDate.setMonth(now.getMonth());
                    expireDate.setMonth(now.getMonth() + 1);
                } else {
                    purchaseDate.setMonth(now.getMonth() - 1);
                    expireDate.setMonth(now.getMonth());
                }
                purchaseDate.setDate(expireDate.getDate() + 1);
            }
            return {
                start: purchaseDate,
                end: expireDate,
            };
        }
        return {
            start: new Date(now.getFullYear(), now.getMonth(), 1),
            end: new Date(now.getFullYear(), now.getMonth() + 1, 0),
        };
    }

    async getDocumentUsedCreationNumber() {
        const trialExpiredMonth = this.feature.dt_trial_expires.split('-', 2).join('-');
        const now = new Date();
        const currentYearMonth = `${now.getFullYear()}-${now.getMonth()}`;
        const isSubscribed = this.mode === MODE.SUBSCRIBED;
        //
        // we don't count global document consumptions in the same month with trial expires
        // - if its not subscribed (in the same month of trial)
        // - way to reset doc num to 5 -> leave to use DocumentStore.getDocumentNumberForMonth()
        //
        if (trialExpiredMonth === currentYearMonth && !isSubscribed) {
            return 0;
        }

        const { start: periodStartDate, end: periodEndDate } = await this.getDocumentRenewalPeriod();
        const today = new Date();
        const purchaseDate = new Date(periodStartDate);
        const expiresDate = new Date(periodEndDate);
        const dayOfRenewal = today.toDateString() === expiresDate.toDateString();

        const records = (await this.company.getAllConsumptionStats()).filter(record => {
            const { year, month, day } = record.data;
            const statDate = new Date(`${year}-${(month + 1)}-${day}`);
            const statDateOnly = dateUtils.getStartOfDay(statDate);
            const purchaseDateOnly = dateUtils.getStartOfDay(purchaseDate);
            const expiresDateOnly = dateUtils.getStartOfDay(expiresDate);

            if (dayOfRenewal) {
                return statDateOnly >= purchaseDateOnly && statDateOnly <= expiresDateOnly;
            }
            return statDateOnly >= purchaseDateOnly && statDateOnly < expiresDateOnly;
        });

        return records.reduce((acc, record) => acc + record.data.documentCreation, 0);
    }

    async getDocumentNumberForSubPeriod() {
        const trialExpires = this.feature.dt_trial_expires;
        const purchaseRecord = await this.getCurrentSubscription();
        let periodStarted = trialExpires;
        let renewalDate = '';
        let dtPurchase;

        if (purchaseRecord) {
            const documentRenewalPeriod = await this.getDocumentRenewalPeriod();
            dtPurchase = dateUtils.format(documentRenewalPeriod.start);
            renewalDate = dateUtils.format(documentRenewalPeriod.end);
        }
        //
        // trial already expired by a purchase, reference date should be the ps date
        //
        if (dtPurchase) {
            periodStarted = dtPurchase;
        }
        //
        // check doc records
        //
        const documentsInPeriod = await this.company.getAllDocument({
            filters: [{
                property: 'dt_created',
                operator: '>=',
                value: periodStarted,
            }, {
                property: 'dt_created',
                operator: '<',
                value: renewalDate,
            }, {
                property: 'confirmed',
                operator: '=',
                value: true,
            }],
        });

        return documentsInPeriod.length;
    }

    async getDaysUntilRenews() {
        const now = new Date();
        const documentRenewalPeriod = await this.getDocumentRenewalPeriod();
        return (documentRenewalPeriod.end.getTime() - now.getTime()) / 1000 / 60 / 60 / 24;
    }

    async getDocumentCreationInfo() {
        const purchaseSetup = await this.getCurrentPurchaseSetup();
        const documentCreation = {
            default: -1,
            extra: {
                remaining: await this.getDocumentAvailableCreationNumber(),
                used: await this.countUsedDocsExtra(),
            },
            used: {
                local: await this.getDocumentNumberForSubPeriod(),
                global: await this.getDocumentUsedCreationNumber(),
            },
            documents: {
                CAN_CREATE_INVOICE: this.getCachedFeatureValue(FEATURES.CAN_CREATE_INVOICE),
                CAN_CREATE_CREDITNOTE: this.getCachedFeatureValue(FEATURES.CAN_CREATE_CREDITNOTE),
                CAN_CREATE_QUOTE: this.getCachedFeatureValue(FEATURES.CAN_CREATE_QUOTE),
                CAN_CREATE_EXPENSE: this.getCachedFeatureValue(FEATURES.CAN_CREATE_EXPENSE),
                CAN_CREATE_EXPENSE_CREDITNOTE: this.getCachedFeatureValue(FEATURES.CAN_CREATE_EXPENSE_CREDITNOTE),
                CAN_USE_JOBSHEETS: this.getCachedFeatureValue(FEATURES.CAN_USE_JOBSHEETS),
                CAN_CREATE_SUPPLIER_INVOICE: this.getCachedFeatureValue(FEATURES.CAN_CREATE_SUPPLIER_INVOICE),
                CAN_CREATE_SUPPLIER_INVOICE_CREDIT: this.getCachedFeatureValue(FEATURES.CAN_CREATE_SUPPLIER_INVOICE_CREDIT),
                CAN_CREATE_PURCHASE_ORDER: this.getCachedFeatureValue(FEATURES.CAN_CREATE_PURCHASE_ORDER),
                CAN_CREATE_SIGNATURE: this.getCachedFeatureValue(FEATURES.CAN_CREATE_SIGNATURE),
            },
            daysTillRenew: -1,
            creationAllowed: true,
            sendAllowed: true,
        };
        //
        // get daysTillRenew
        //
        const daysTillRenew = await this.getDaysUntilRenews();

        if (daysTillRenew > 0) {
            documentCreation.daysTillRenew = Math.round(daysTillRenew);
        } else {
            documentCreation.daysTillRenew = Math.ceil(daysTillRenew);
        }
        //
        // subscribed, get number based on product
        //
        documentCreation.default = purchaseSetup.data.documentCreation;
        documentCreation.sendAllowed = Boolean(purchaseSetup.data.documentSend);
        //
        // set creationAllowed flag
        //
        const localDiff = documentCreation.default - documentCreation.used.local;
        const globalDiff = documentCreation.default - documentCreation.used.global;

        if (documentCreation.default === -1) {
            documentCreation.creationAllowed = true;
        } else {
            if (localDiff <= 0 || globalDiff <= 0) {
                //
                // out of monthly limit, so check extra
                //
                documentCreation.creationAllowed = false;
            }
            //
            // last check for any extra left?
            //
            if (documentCreation.extra.remaining > 0) {
                documentCreation.creationAllowed = true;
            }
        }
        return documentCreation;
    }

    isExpired() {
        return this.mode === 'expired';
    }
}
